import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ServicesView from "../views/ServicesView.vue";
// import CategoryView from "@/views/CategoryView.vue"
import ServiceListView from "@/components/services/ServiceListView"
import CategoryListView from "@/components/services/CategoryListView"
import ServiceDetailsView from "@/components/services/ServiceDetailsView"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "Home",
      component: HomeView,
      meta: { title: 'V N R C & Associates Chartered Accountants' }
    },
    {
      path: "/about",
      name: "About Us",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/our-people",
      name: "Our People",
      component: () => import("../views/OurPeopleView.vue"),
    },
    {
      path: "/services/:country",
      component: ServicesView,
      children: [
        {
          path: '',
          component: CategoryListView,
        },
        {
          path: ':category',
          component: ServiceListView,
        },
        {
          path: ':category/:service',
          component: ServiceDetailsView,
        }
        
      ]
    },
    {
      path: "/business-entities",
      name: "Business Entities",
      component: () => import("../views/BusinessView.vue"),
    },
    {
      path: "/contact-us",
      name: "Contact Us",
      component: () => import("../views/ContactView.vue"),
    },
    {
      path: "/jobs",
      name: "Jobs",
      component: () => import("../views/JobsView.vue"),
    }
  ],
});

router.beforeEach((to, from) => {
  console.log(from)
  document.title = to.meta?.title ?? 'V N R C & Associates Chartered Accountants'
})

export default router;
