<template>
    <div class="col-xl-4 col-lg-6 col-md-6 my-3">
        <div class="d-flex flex-column">
            <div class="wrapper">
                <div class="single-card">
                    <div class="front">
                        <img :style="{ 'width': '100%', 'height': '300px' }" :src="getImagePath(props.item.image)"
                            class="rounded-4" alt="">
                        <div :style="{ 'width': '100%', 'height': '300px' }"
                            class="position-absolute top-0 bottom-0 start-0 end-0 rounded-4 bg-dark opacity-25"></div>
                        <div class="position-absolute top-0 bottom-0 start-0 end-0 fs-1 py-5 m-3 text-white">
                            {{ props.item.name }}</div>
                    </div>
                    <div class="back">
                        <img :style="{ 'width': '100%', 'height': '300px' }" :src="getImagePath(props.item.image)"
                            class="rounded-4 position-relative" alt="">
                        <div :style="{ 'width': '100%', 'height': '300px' }"
                            class="position-absolute top-0 bottom-0 start-0 end-0 rounded-4 bg-dark opacity-50"></div>
                        <div
                            class="rounded-4 position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column align-items-center justify-content-center">
                            <div class="content p-3 position-relative">
                                <h2 class="text-white truncate">{{ props.item.name }}</h2>
                                <h6 class="text-light truncate">{{ props.item.description }}</h6>
                            </div>
                            <button class="button1 text-white" @click="navigateRoute($event, props.item)"
                                aria-hidden="true">
                                Details
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import router from "@/router";
import { defineProps } from "vue"
import { useRoute } from 'vue-router'
const route = useRoute()
const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})

const getImagePath = (filename) => {
    return require(`@/assets/images/services/registration/${filename}`);
};

function navigateRoute(event, data) {
    const countryCode = route.params.country
    const category = route.params.category
    router.push({ path: `/services/${countryCode}/${category}/${data.rPath}` });
}
</script>
<style>
.wrapper,
.single-card {
    height: 300px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
}

.wrapper {
    perspective: 900px;
}

.single-card {
    text-align: center;
    transition: all 1.5s cubic-bezier(0.7, -0.5, 0.3, 1.8);
    transform-style: preserve-3d;
}

.wrapper:hover .single-card {
    transform: rotateY(180deg);
}

.front,
.back {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.front {
    cursor: pointer;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100% 100%;
    border-radius: 50px;
    box-sizing: border-box;
}

.back {
    transform: rotateY(180deg);
    position: absolute;
    border-radius: 50px;
    height: 300px;
    width: 100%;
    background: #fff;
}

.button1 {
    grid-row: -1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    padding: 0 1.5em;
    height: 3em;
    line-height: 2.9em;
    min-width: 1em;
    background-color: transparent;
    border: solid 2px #fff;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    backface-visibility: hidden;
    transition: 0.3s ease-in-out;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 50px white;
        text-shadow: none;
    }
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>