<template>
  <div>
    <CarouselComp :images="images"></CarouselComp>
    <section class="mt-3 pt-1 pb-5 mb-3">
      <div class="container p-2">
        <div class="row align-items-center justify-content-center">
          <div :style="{ 'background-color': 'white' }">
            <div class="text-uppercase" :style="{ 'letter-spacing': '0.5px', 'font-family': 'Times New Roman, Times, serif' }">
              <span class="fs-2">Welcome To </span><span class="fs-1 fw-bold text-color">V N R C &
                Associates</span>
            </div>
            <hr class="dotted-line">
            <div class="fw-bold mb-3" :style="{ 'letter-spacing': '1px' }">Chartered Accountants</div>
            <div class="about-point1 mb-2">
              We are a dynamic team of Chartered Accountants based in Hyderabad, established in 2020. Our comprehensive
              suite of services includes Audit & Assurance, Tax Planning, Company Law Advisory, as well as Financial and
              Business Advisory Services.
            </div>
            <div class="about-point2 mx-5">
              Our client-focused approach addresses the evolving requirements of diverse businesses, ranging from small
              enterprises, medium-sized to large corporations.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-blue text-white pt-4 pb-5 overflow-hidden">
      <div class="container">
        <div class="row justify-content-center my-4">
          <div class="col col-lg-8 col-xl-6 display-5 fw-bold text-center">
            Our Services
          </div>
        </div>
        <div class="row justify-content-center text-center">
          <div class="col-lg-4 col-md-6 col-12 overflow-hidden" data-aos="fade-left" data-aos-duration="3000">
            <div class="d-flex flex-column mb-5 p-5 item bg-light rounded-4">
              <div class="mb-3 img-container">
                <img :src="images[0].url" alt="gst" class="rounded-4 img-zoom"
                  :style="{ 'width': '100%', 'height': '150px' }">
              </div>
              <div class="d-flex flex-column mt-3">
                <h2 class="d-flex align-items-center justify-content-between">
                  <a href="/services/india" class="fs-4 fw-bold text-dark text-decoration-none">{{
                    images[0].title }}</a>
                  <a href="/services/india" class="combine fs-6 fw-bold text-dark text-decoration-none">
                    <span style="margin-right: 3px;" class="know-more">KNOW MORE</span>
                    <font-awesome-icon class="fa-angle-rigle" :icon="['fas', 'angle-double-right']" />
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12" data-aos="fade-left" data-aos-duration="3000">
            <div class="d-flex flex-column mb-5 p-5 bg-light rounded-4 item">
              <div class="mb-3 img-container">
                <img :src="images[1].url" alt="Tax" class="rounded-4 img-zoom"
                  :style="{ 'width': '100%', 'height': '150px' }">
              </div>
              <div class="d-flex flex-column mt-3">
                <h2 class="d-flex align-items-center justify-content-between">
                  <a href="http://rightratios.co.uk/" class="fs-4 fw-bold text-dark text-decoration-none">{{
                    images[1].title }}</a>
                  <a href="http://rightratios.co.uk/" class="combine fs-6 fw-bold text-dark text-decoration-none">
                    <span style="margin-right: 3px;" class="know-more">KNOW MORE</span>
                    <font-awesome-icon class="fa-angle-rigle" :icon="['fas', 'angle-double-right']" />
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12" data-aos="fade-left" data-aos-duration="3000">
            <div class="d-flex flex-column mb-3 p-5 bg-light rounded-4 item">
              <div class="mb-3 img-container">
                <img :src="images[2].url" alt="audit" class="rounded-4 img-zoom"
                  :style="{ 'width': '100%', 'height': '150px' }">
              </div>
              <div class="d-flex flex-column mt-3">
                <h2 class="d-flex align-items-center justify-content-between"><a href="/services/usa"
                    class="fs-4 fw-bold text-dark text-decoration-none">{{
                      images[2].title }}</a>
                  <a href="/services/usa" class="combine fs-6 fw-bold text-dark text-decoration-none">
                    <span style="margin-right: 3px;" class="know-more">KNOW MORE</span>
                    <font-awesome-icon class="fa-angle-rigle" :icon="['fas', 'angle-double-right']" />
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="my-5 align-items-center justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div class="col-12 col-lg-7 col-xxl-6 my-lg-0" data-aos="flip-left" data-aos-offset="300"
          data-aos-easing="ease-out-cubic">
          <FormComp></FormComp>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import CarouselComp from "@/components/CarouselComp.vue"
import FormComp from '@/components/FormComp.vue';
import url1 from '@/assets/images/services/India.jpg';
import url2 from '@/assets/images/services/UK.jpg';
import url3 from '@/assets/images/services/USA.jpg';
const images = [
  {
    url: url1,
    title: 'India',
    image: "serviceHome1.jpg",
  },
  {
    url: url2,
    title: 'UK',
    image: "serviceHome2.jpg",
  },
  {
    url: url3,
    title: 'USA',
    image: "serviceHome3.jpg",
  }
]
</script>
<style>
.bg-gray-2 {
  background-color: #f3f4f6;
}

.bg-blue {
  background-color: #023E62;
}

.bg-green {
  background-color: #65AC4C;
}

p {
  color: #4c5165;
}

.img-container {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dotted-line {
  border: none;
  border-top: 4px dotted black !important;
  height: 1px;
  width: 35%;
  margin: 25px auto;
}

.text-color {
  color: #E69F2A;
}

.combine:hover .know-more {
  color: #65AC4C;
}

.combine:hover .fa-angle-rigle {
  color: #65AC4C;
}

.about-point1, .about-point2 {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.5px;
}
</style>
