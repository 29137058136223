<template>
  <div class="col-xl-4 col-lg-6 col-md-6 my-3">
    <div class="d-flex flex-column p-3 menu-container rounded-4 hover-item">
      <div class="mb-3 img-container image-wrapper">
        <img :style="{ 'width': '100%', 'height': '200px' }" :src="getImagePath(props.item.image)" class="rounded-4 img-zoom"
          alt="">
        <div class="image-overlay rounded-4"></div>
      </div>
      <div class="d-flex flex-column service-details hover-content">
        <h3 class="text-center fs-1 fw-bold truncate tile-title">{{ props.item.name }}</h3>
        <div class="d-flex mt-1">
          <div class="text-start description truncate mr-3">{{ props.item.description }}</div>
          <div class="arrow" @click="navigateRoute($event, props.item)">
            <div class="circle circle1"></div>
            <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-white" />
            <div class="circle circle2"></div>
          </div>
        </div>
      </div>
      <div class="bottom-bdr"></div>
    </div>
  </div>
</template>
<script setup>
import router from "@/router";
import { defineProps} from "vue"
import { useRoute } from 'vue-router'
const route = useRoute()
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

function navigateRoute(event, data) {
  const countryCode = route.params.country
  if (countryCode == "india") {
    router.push({ path: `/services/${countryCode}/${data.rPath}` });
  } else if (countryCode == "usa"){
    router.push({ path: `/services/${countryCode}/detailsView/${data.rPath}` });
  }
  
}
const getImagePath = (filename) => {
  return require(`@/assets/images/services/${filename}`);
};
</script>
<style>

.hover-item {
  max-height: 450px !important;
  min-height: 450px;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.service-details {
  width: 90%;
  left: 0;
  right: 0;
  max-height: 260px;
  min-height: 260px;
  background: rgba(255, 255, 255);
  padding: 30px;
  margin-top: -15%;
  position: relative;
  border-left: 4px solid #65AC4C;
  border-right: 4px solid #65AC4C;
  border-radius: 0px 35px 0px 0px;
}

.bottom-bdr {
  width: 10%;
  height: 4px;
  background-color: #65AC4C;
}

.arrow {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #023E62;
  border-radius: 50%;
  z-index: 1;
}

.arrow:hover {
  background-color: #65AC4C;
  cursor: pointer;
}

.description {
  width: 70%;
}

.image-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0);
  transition: opacity 0.5s ease, transform 1.5s ease;
  transform-origin: center center;
  transform: perspective(1000px) rotateX(0deg);
}

.hover-item:hover .image-overlay {
  opacity: 0.3;
  transform: perspective(1000px) rotateX(-180deg);
}

.circle {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  transition: width 0.8s ease, height 0.8s ease;
}

.circle1 {
  width: 25px;
  height: 25px;
  margin-right: 60px;
}

.circle2 {
  margin-left: 60px;
  width: 0px;
  height: 0px;
}

.arrow:hover .circle2 {
  width: 25px;
  height: 25px;
}

.arrow:hover .circle1 {
  width: 0px;
  height: 0px;
}
</style>