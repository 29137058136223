<template>
    <section class="overflow-hidden">
        <div class="container">
          <div class="row text-center mx-3 my-3">
            <CategoryTile v-for="(country, index) in categories" :key="index" :item="country" />
          </div>
        </div>
      </section>
  </template>
  <script setup>
  import { indiaServices, usaServices } from '@/data/provider/contentProvider'
  import CategoryTile from '@/components/tiles/CategoryTile.vue';
  import { ref, onMounted } from "vue"
  import { onBeforeRouteUpdate, useRoute } from 'vue-router'
  const route = useRoute()
  
  const categories = ref([]);
  
  onMounted(() => {
    loadData(route.params.country)
  })
  
  onBeforeRouteUpdate((to, from, next) => {
    categories.value = []
    loadData(to.params.country)
    next()
  })
  
  function loadData(countryCode) {
    if (countryCode == "india") {
      categories.value = indiaServices().categories
    } else if (countryCode == "usa") {
      categories.value = usaServices().categories
    }
  }
  </script>
  <style></style>
  