
import contact from '../store/contact.json'
import indiaData from '../store/india_data.json'
import usaData from '../store/usa_data.json'
import servicesData from '../store/services_data.json'
import checkList from '../store/checklist.json'

export const servicesList = () => {
    return servicesData
}
export const contacts = () => {
    return contact
}


export const indiaServices = () => {
    return indiaData
}

export const usaServices = () => {
    return usaData
}

export const userCheckList = () => {
    return checkList
}


export const itr = () => {
    

}