import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXTwitter, faLinkedin, faSquareInstagram, faFacebookSquare, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import store from './store/store';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'aos/dist/aos.css'

// angle-double-right

import { faClock, faHouse, faGreaterThan, faAnglesRight, faArrowRight, faPhoneVolume, faLocationDot, faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons"
library.add(faXTwitter, faHouse, faFacebookSquare, faAnglesRight, faArrowRight, faGreaterThan, faWhatsapp, faLinkedin, faSquareInstagram, faLocationDot, faClock, faPhoneVolume, faEnvelopeOpenText)

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(router).use(store).mount('#app')
