<template>
    <div class="container p-5">
        <div class="row text-center mx-3 overflow-hidden" v-for="category in ServicesList" :key="category.id">
            <ServiceTile v-if="category.service == service" :items="category"></ServiceTile>
        </div>
    </div>
</template>
<script setup>
import ServiceTile from '@/components/tiles/ServiceTile.vue'
import { onMounted, ref } from 'vue';
import { userCheckList } from '@/data/provider/contentProvider'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
const route = useRoute()

const ServicesList = ref([])
const catgory = ref()
const service = ref()

onBeforeRouteUpdate((to, from, next) => {
    catgory.value = []
    service.value = to.params.service
    loadData(to.params.category)
    next()
})

onMounted(() => {
    loadData(route.params.category)
})

function loadData(category) {
    catgory.value = category
    const countryCode = route.params.country
    if (service.value == null)
        service.value = route.params.service
    if (countryCode == "india") {
        ServicesList.value = userCheckList().checklist
    } else if (countryCode == "usa") {
        ServicesList.value = userCheckList().userCheckList
    }

    console.log(ServicesList.value)
}
</script>