<template>
    <div class="d-flex flex-row">
        <a href="https://www.facebook.com" target="_blank"
            class="social border my-2 mx-1 border-success rounded-circle d-flex justify-content-center align-items-center">
            <font-awesome-icon :icon="['fab', 'square-facebook']" size="xl" class="social-icon p-2" />
        </a>
        <a href="https://www.twitter.com" target="_blank"
            class="social border my-2 mx-1 position-relative border-success rounded-circle d-flex justify-content-center align-items-center">
            <font-awesome-icon :icon="['fab', 'x-twitter']" size="xl" class="social-icon p-2" />
        </a>
        <a href="https://linkedin.com/" target="_blank"
            class="social border position-relative my-2 mx-1 border-success rounded-circle d-flex justify-content-center align-items-center">
            <font-awesome-icon :icon="['fab', 'linkedin']" size="xl" class="social-icon p-2" />
        </a>
        <a href="https://www.instagram.com/" target="_blank"
            class="social border my-2 mx-1 position-relative border-success rounded-circle d-flex justify-content-center align-items-center">
            <font-awesome-icon :icon="['fab', 'square-instagram']" size="xl" class="social-icon p-2" />
        </a>
    </div>
</template>