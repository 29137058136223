<template>
  <div class="myCarousel">
    <div class="myCarousel-container" ref="carouselContainerRef">
      <div v-for="(image, index) in images" :key="index" class="myCarousel-item">
        <img :style="{ 'width': '100%', 'height': '100%', 'object-fit': 'fill' }" :src=currentImage.imagePath
          alt="Carousel Image" class="myCarousel-img" />
        <div class="position-absolute top-50 start-50 text-center translate-middle text-white slider-info">
          <h4>WELCOME TO</h4>
          <h3>V N R C & ASSOCIATES</h3>
          <p class="text-white">CHARTERED ACCOUNTANTS</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, watch, computed } from 'vue';

const props = defineProps({
  images: {
    type: Array,
    required: true
  }
});

const images = ref(props.images)

const itemWidth = ref(0);
const currentIndex = ref(0)
let autoAnimationTimer = null;

const getImagePath = (filename) => {
  return require(`@/assets/images/services/${filename}`);
};

const updateCarousel = () => {
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
};

const carouselContainerRef = ref(null);

watch(carouselContainerRef, () => {
  if (carouselContainerRef.value) {
    itemWidth.value = carouselContainerRef.value.offsetWidth;
    updateCarousel();
  }
});

const startAutoAnimation = () => {
  autoAnimationTimer = setInterval(() => {
    updateCarousel();
  }, 3000);

};

onMounted(() => {
  itemWidth.value = carouselContainerRef.value.offsetWidth;
  applyAnimation();
  startAutoAnimation()

});

const applyAnimation = () => {
  const elements = document.querySelectorAll('.myCarousel-img');
  elements.forEach((element, index) => {

    const animationDelay = 2 * index * 3;
    element.style.animationDelay = `${animationDelay}s`;
    element.classList.add('fade');
  });
}

const stopAutoAnimation = () => {
  clearInterval(autoAnimationTimer);
};

onUnmounted(() => {
  stopAutoAnimation();
});

const currentImage = computed(() => {
  return {
    image: images.value[currentIndex.value].image,
    imagePath: getImagePath(images.value[currentIndex.value].image),
  };
});
</script>

<style scoped>
.slider-info p {
  font-size: 35px;
  font-weight: 600;
  margin: 0 auto;
  line-height: 35px;
  letter-spacing: 2px;
}

.slider-info h3 {
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 3px;
}

.slider-info h4 {
  font-size: 60px;
  letter-spacing: 2px;
}

.myCarousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  font-family: "Times New Roman", Times, serif;
}

@keyframes fade {
  from {
    opacity: 0.85;
  }

  to {
    opacity: 1;
  }
}

.myCarousel-container {
  display: flex;
  transition: transform 0.3s ease;
  height: 600px;
}

.myCarousel-item {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
}

.myCarousel-img {
  animation: fade 3s ease-in-out infinite;
}
</style>