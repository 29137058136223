import { createStore } from 'vuex';

export default createStore({
  state: {
    countries: []
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    }
  },
  actions: {

    async fetchCountries({ commit }) {
      try {
        const response = await fetch('../countries.json');
        const data = await response.json();
        commit('setCountries', data.countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    // updateItems({ commit }, items) {
    //   commit('setServiceMenuItems', items);
    // }
  },
  getters: {
    // getServiceMenuItems: state => state.serviceMenuItems
    getCountries(state) {
      return state.countries;
    },
  }
});
