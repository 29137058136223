<template>
    <div class="p-4 p-sm-5 pb-sm-4 shadow rounded">
        <form class="row g-4 needs-validation" id="myForm" novalidate>
            <h4 class="col-12 text-center">
                Send Us a Message
            </h4>
            <div class="col-md-6">
                <div class="d-flex align-items-start">
                    <label for="nameForm" class="form-label">Full Name</label>
                </div>
                <input type="text" class="form-control" name="nameForm" id="nameForm" required>
                <div class="invalid-feedback">
                    Please your name!
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex align-items-start">
                    <label for="emailForm" class="form-label">Email Address</label>
                </div>
                <input type="email" class="form-control" name="emailForm" id="emailForm" required>
                <div class="invalid-feedback">
                    Please provide email!
                </div>
            </div>

            <div class="col-md-6">
                <div class="d-flex align-items-start">
                    <label for="phoneForm" class="form-label">Phone Number</label>
                </div>
                <input type="text" class="form-control" name="phoneForm" id="phoneForm" required>
                <div class="invalid-feedback">
                    Please provide a valid phone Number!
                </div>
            </div>

            <div class="col-md-6">
                <div class="d-flex align-items-start">
                    <label for="subjectForm" class="form-label">Subject</label>
                </div>
                <input type="text" class="form-control" name="subjectForm" id="subjectForm" required>
                <div class="invalid-feedback">
                    Please enter subject!
                </div>
            </div>

            <div class="mb-3">
                <div class="d-flex align-items-start">
                    <label for="formControlTextArea" class="form-label">Your Message</label>
                </div>
                <textarea class="form-control" name="formControlTextArea" id="formControlTextArea" rows="3" required></textarea>
                <div class="invalid-feedback">
                    Please enter a message in the textarea.
                </div>
            </div>
            <div class="col-12 text-center">
                <button type="submit" class="btn btn-primary btn-lg w-50 w-lg-60" id="sendMessage" disabled>
                    Send Message
                </button>
            </div>
            <!-- <div class="col-12 text-center" id="yourMessageIsSent"></div> -->
        </form>
    </div>
</template>
<style lang="scss" scoped>
@import '@/styles/__variables.scss';
.btn-primary {
    background-color: $blue-color;
}
.btn-primary:hover {
    background-color: $green-color;
}
</style>