<template>
    <div class="bg-white mb-2 d-flex flex-row justify-content-center align-items-center">
        <div class="d-flex flex-row justify-content-center align-items-center mx-2" :style="{ 'min-width': '350px' }">
            <span class="icon-logo"><img src="./../assets/ca_logo.jpg" /></span>
            <span class="d-flex flex-column">
                <span class="fs-5 fw-bold middlebar-title">V N R C & ASSOCIATES</span>
                <span class="fs-6 fw-bold middlebar-subtitle">Chartered Accountants</span>
            </span>
        </div>
        <div class="mx-5 mx-lg-5 mx-xl-5 mx-xxl-5 d-none d-lg-block" :style="{ 'min-width': '150px' }">
            <div class="d-flex flex-row justify-content-center align-items-center mx-2">
                <font-awesome-icon :icon="['fas', 'phone-volume']" beat-fade size="2xl" class="social-icon" />
                <div class="fs-6 fw-bold mx-2">Call Us Today <br> +91
                    94 93 92 9333<br>+91 94 93 92 9810</div>
            </div>
        </div>
        <div class="d-none d-lg-block">
            <div class="d-flex flex-row justify-content-center align-items-center mx-2">
                <font-awesome-icon :icon="['fas', 'envelope-open-text']" shake size="2xl" class="social-icon" /> <span
                    class="fs-6 fw-bold mx-2">Send Email<br>Info@Vnrcassociates.com<br>Contact@Vnrcassociates.com</span>
            </div>
        </div>
    </div>
</template>