<template>
  <TopBar />
  <MiddleBar />
  <MainMenu />
  <router-view></router-view>
  <FooterComp />
</template>

<script setup>
import TopBar from '@/components/TopBar.vue';
import MiddleBar from '@/components/MiddleBar.vue';
import MainMenu from "@/components/MainMenu.vue"
import FooterComp from '@/components/FooterComp.vue';
import { onMounted } from "vue"
import AOS from "aos";

onMounted(() => {
  AOS.init()
})
</script>

<style lang="scss">
@import '@/styles/__style.scss';
@import '@/styles/__variables.scss';

#app {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  height: 100vh;
  width: 100%;
}
</style>
