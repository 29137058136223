<template>
  <section class="overflow-hidden">
    <div class="container p-5">
      <div class="row text-center mx-3 overflow-hidden" v-for="category in ServicesList" :key="category.id">
        <ServicesTile v-if="category.rPath == catgory" v-for="service in category.ServicesList" :key="service.id"
          :item="service"></ServicesTile>
      </div>
    </div>
  </section>
</template>
<script setup>
import ServicesTile from '@/components/tiles/ServicesTile.vue'
import { servicesList } from '@/data/provider/contentProvider'
import { onMounted, ref } from 'vue';
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
const route = useRoute()

onBeforeRouteUpdate((to, from, next) => {
  catgory.value = []
  loadData(to.params.category)
  next()
})


const ServicesList = ref([])
const catgory = ref()

onMounted(() => {
  loadData(route.params.category)
})

function loadData(category) {
  catgory.value = category
  const countryCode = route.params.country
  if (countryCode == "india") {
    ServicesList.value = servicesList().services
  } else if (countryCode == "usa") {
    ServicesList.value = servicesList().usaServices
  }
}
</script>
<style></style>
  