<template>
  <nav class="menu-container navbar navbar-expand-lg navbar-dark text-white">
    <button class="navbar-toggler collapsed ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center align-items-center" id="navbarNav">
      <ul class="navbar-nav mx-auto d-flex">
        <li v-for="item in headerMenu.navigation" :key="item.id" :class="{ 'nav-item': true, 'dropdown': item.subMenu }">
          <a class="nav-link text-white menu-padding fw-bold"
            :class="{ 'dropdown-toggle': item.subMenu && item.subMenu.length > 0, 'active': isMenuItemActive(item.id) }"
            v-bind:data-bs-toggle="getItemToggleAttribute(item)" @click="handleMenuItemClick($event, item)">{{ item.label
            }}</a>
          <ul class="dropdown-menu border-3 custom-border-color border-top sub-items-container"
            v-if="item.subMenu && item.subMenu.length > 0">
            <li v-for="(subItem, index) in item.subMenu" :key="subItem.id" class="dropend dropdown-submenu">
              <a v-if="subItem.rPath == 'uk'" href="http://rightratios.co.uk/" target="_self"
                class="dropdown-item text-dark"
                :class="{ 'dropdown-toggle': subItem.subMenu && subItem.subMenu.length > 0, 'active': isMenuItemActive(subItem.id) }">{{
                  subItem.name }}</a>
              <a v-else class="dropdown-item text-dark"
                :class="{ 'dropdown-toggle': subItem.subMenu && subItem.subMenu.length > 0, 'active': isMenuItemActive(subItem.id) }"
                @click="handleSubItemClick(subItem)">{{ subItem.name }}</a>
              <ul class="dropdown-menu sub-menu-container border-5" v-if="subItem.subMenu && subItem.subMenu.length > 0">
                <li v-for="(subSubItem, index) in subItem.subMenu" :key="subSubItem.id"
                  class="dropend dropdown-subsubmenu">
                  <a class="dropdown-item text-dark"
                    :class="{ 'dropdown-toggle': subSubItem.subMenu && subSubItem.subMenu.length > 0, 'active': isMenuItemActive(subSubItem.id) }"
                    @click="handleSubItemClick(subItem, subSubItem)">{{ subSubItem.name }}</a>
                  <ul class="dropdown-menu sub-sub-menu-container border-5 dropend"
                    v-if="subSubItem.subMenu && subSubItem.subMenu.length > 0">
                    <li v-for="(subSubSubItem, index) in subSubItem.subMenu" :key="subSubSubItem.id"
                      class="dropdown-subsubsubmenu">
                      <a class="dropdown-item text-dark"
                        :class="{ 'dropdown-toggle': subSubSubItem.subMenu && subSubSubItem.subMenu.length > 0, 'active': isMenuItemActive(subSubSubItem.id) }"
                        @click="handleSubItemClick(subItem, subSubItem, subSubSubItem)">{{ subSubSubItem.name }}</a>
                      <hr v-if="index < subSubItem.subMenu.length - 1" class="dropdown-divider" />
                    </li>
                  </ul>
                  <hr v-if="index < subItem.subMenu.length - 1" class="dropdown-divider" />
                </li>
              </ul>
              <hr v-if="index < item.subMenu.length - 1" class="dropdown-divider" />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script setup>
import headerMenu from "./../data.json";
import { ref } from "vue";
import { useRouter } from "vue-router";
// import { useStore } from 'vuex';
const router = useRouter();
// const store = useStore();

const activeMenuItem = ref(null);
const submenuVisible = ref(false);

function handleMenuItemClick(event, item) {
  event.preventDefault()
  activeMenuItem.value = item.id;
  if (item.label !== 'Services') navigateToRoute(item.label);
}

function handleSubItemClick(subItem, subSubItem = null, subSubSubItem=null) {
  subSubSubItem ? navigateToRoute(subItem.rPath, subSubItem.rPath, subSubSubItem.rPath) : 
  subSubItem ? navigateToRoute(subItem.rPath, subSubItem.rPath) : navigateToRoute(subItem.rPath);
  
}


function isMenuItemActive(itemId) {
  return activeMenuItem.value === itemId && submenuVisible.value;
}

const navigateToRoute = (country, category=null, service=null) => {
  if (country === "india" || country == "usa") {
    let goTo = `/services/${country}`
    if(country == "usa" && category != null) {
      goTo += `/detailsView`
    }
    if(category) {
      goTo += `/${category}`
    }
    if(service) {
      goTo += `/${service}`
    }
    router.push({ path: goTo });
  } else if (country === "uk") {
    window.open("http://rightratios.co.uk", "_blank");
  } else {
    router.push({ name: country });
  }
};

const getItemToggleAttribute = (item) => {
  return item.subMenu && item.subMenu.length > 0 ? "dropdown" : null;
}
</script>
<style lang="scss">
.menu-padding {
  margin-right: 5rem;
}

.sub-items-container {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.sub-menu-container {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  left: 100%;
  top: 0;
}

.sub-sub-menu-container {
  display: none;
  background-color: #f9f9f9;
  max-height: 350px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 100%;
  top: 0;
  overflow-y: auto;
}

.nav-link:hover {
  cursor: pointer;
}

.dropdown:hover .sub-items-container,
.dropdown-submenu:hover .sub-menu-container,
.dropdown-subsubmenu:hover .sub-sub-menu-container {
  cursor: pointer;
  display: block;
}

.list-group {
  max-height: 300px;
  margin-bottom: 10px;
  overflow: scroll;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0px !important;
}

.custom-border-color {
  border-color: #65AC4C !important;
}

.dropdown-item:hover {
  color: white !important;
  background-color: #023E62 !important;
}

.dropdown-item:active {
  background-color: #65AC4C !important;
}
</style>